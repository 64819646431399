export const clientNameFooter = "FLAVIA FROES";
export const titleMain = "";
export const subtitleMain = "No Yoga, o corpo se torna um canal para acessar estados mais elevados de consciência e conexão.";

export const aspectRatioLogoAbout = '1'; // width/height
export const aspectRatioLogoMain = '1'; // width/height

export const borderRadiusButtons = '10px';

export const nomeFornecedorCompleto = "FLAVIA FROES PEREGRINO";
export const nomeFornecedor = "FLAVIA FROES";
export const artigo = "a";
export const artigoCaps = "A";

export const footerSocialData = [
  {
    type: "material-community",
    name: "instagram",
    link: "https://www.instagram.com/flaviafroes.yoga/",
    profile: "@flaviafroes.yoga"
  },
  {
    type: "material-community",
    name: "whatsapp",
    link: "https://wa.me/+5511985882856",
    number: "(11) 98588-2856"
  },
];

export const drawerClosedLinks = [
  {
    title: "Fale Comigo",
    link: "https://wa.me/+5511985882856",
  },
];

export const linkAppleStore = null
export const linkPlayStore = null

export const hasOutsideAboutPage = false;
export const drawerOpenedLinks = [];